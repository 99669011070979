import apiHelper from './index';

const fetchVideos = async (taskId, spinner = true, queryParams = {}) => {
  return await apiHelper.get(
    `organization/task/videos/${taskId}`,
    spinner,
    queryParams
  );
};

const createEntityForVideo = async (payload, spinner = false) => {
  const [error, data] = await apiHelper.post(
    'organization/entity/',
    payload,
    spinner
  );
  return data;
};

const fetchOrganizationTags = async (status, spinner = true) => {
  return await apiHelper.get(
    `organization/task_record/tags`,
    spinner
  );
};

const addNewComment = async (payload, spinner = false) => {
  const [error, data] = await apiHelper.post(
    `organization/task_record/comments?entity_id=${null}`,
    payload,
    spinner
  );
  return data;
};

const updateTaskRecord = async (id, payload, spinner = false) => {
  const res = await apiHelper.patch(
    `organization/task_record/${id}/`,
    payload,
    spinner
  );
  return res;
};

const deleteComment = async (id, spinner = false) => {
  return await apiHelper.remove(
    `organization/task_record/comments/${id}`,
    spinner
  );
};

const increaseCommentCount = async (taskRecord, spinner = false) => {
  const { id, no_of_comments } = taskRecord;
  return await apiHelper.patch(
    `organization/task_record/${id}/`,
    {
      no_of_comments: no_of_comments + 1,
    },
    spinner
  );
};

const decreaseCommentCount = async (taskRecord, spinner = false) => {
  const { id, no_of_comments } = taskRecord;
  const updatedComments = no_of_comments - 1 <= 0 ? 0 : no_of_comments - 1;
  return await apiHelper.patch(
    `organization/task_record/${id}/`,
    {
      no_of_comments: updatedComments,
    },
    spinner
  );
};

const getCommentsForEntity = async (id, spinner = false) => {
  const [error, data] = await apiHelper.get(
    `organization/task_record/comments?entity_id=${id}`,
    spinner
  );
  if (!error) return data;
};

export const getPresignedUrl = async (payload, spinner = false) => {
  return await apiHelper.post('generic/generate_new_url/', payload, spinner);
};

export const getVideoMetaData = async (payload, spinner = false) => {
  return await apiHelper.post(
    'organization/get_video_metadata',
    payload,
    spinner
  );
};

export default {
  fetchVideos,
  createEntityForVideo,
  addNewComment,
  updateTaskRecord,
  deleteComment,
  increaseCommentCount,
  decreaseCommentCount,
  getCommentsForEntity,
  getPresignedUrl,
  getVideoMetaData,
  fetchOrganizationTags
};
